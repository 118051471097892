export default [

  // data company
  {
    path: '/syarat',
    name: 'syarat',
    component: () => import('@/views/appsalatiga/syarat/List.vue'),
    meta: {
      pageTitle: 'Data Syarat & Ketentuan',
      breadcrumb: [
        {
          text: 'Syarat',
          active: true,
        },
      ],
    },
  },


]
