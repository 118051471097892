export default [

  {
    path: '/pembuatan-sip',
    name: 'pembuatan-sip',
    component: () => import('@/views/appsalatiga/pembuatan-sip/List.vue'),
    meta: {
      pageTitle: 'Data Pembuatan SIP',
      breadcrumb: [
        {
          text: 'Pembuatan SIP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembuatan-sip/tambah',
    name: 'pembuatan-sip-tambah',
    component: () => import('@/views/appsalatiga/pembuatan-sip/form.vue'),
    meta: {
      pageTitle: 'Form Data SIP',
      breadcrumb: [
        {
          text: 'Form Data SIP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembuatan-sip/:idSIP/ubah',
    name: 'pembuatan-sip-ubah',
    component: () => import('@/views/appsalatiga/pembuatan-sip/form.vue'),
    meta: {
      pageTitle: 'Form Data SIP',
      breadcrumb: [
        {
          text: 'Form Data SIP',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pembuatan-sip/:idPendaftar/:idSIP/detail',
    name: 'pembuatan-sip-detail',
    component: () => import('@/views/appsalatiga/pembuatan-sip/detail.vue'),
    meta: {
      pageTitle: 'Detail Dokumen',
      breadcrumb: [
        {
          text: 'Detail Dokumen',
          active: true,
        },
      ],
    },
  },

]
