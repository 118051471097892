export default [

    // data company
    {
      path: '/progress',
      name: 'progress',
      component: () => import('@/views/appsalatiga/progress/List.vue'),
      meta: {
        pageTitle: 'Data Progress',
        breadcrumb: [
          {
            text: 'Progress',
            active: true,
          },
        ],
      },
    },
  
  
  ]
  