export default [

    {
      path: '/pencabutan-sip',
      name: 'pencabutan-sip',
      component: () => import('@/views/appsalatiga/pencabutan-sip/List.vue'),
      meta: {
        pageTitle: 'Data Pencabutan SIP',
        breadcrumb: [
          {
            text: 'Pencabutan SIP',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pencabutan-sip/tambah',
      name: 'pencabutan-sip-tambah',
      component: () => import('@/views/appsalatiga/pencabutan-sip/form.vue'),
      meta: {
        pageTitle: 'Ajukan Pencabutan SIP',
        breadcrumb: [
          {
            text: 'Ajukan Pencabutan SIP',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pencabutan-sip/:idSIP/ubah',
      name: 'pencabutan-sip-ubah',
      component: () => import('@/views/appsalatiga/pencabutan-sip/form.vue'),
      meta: {
        pageTitle: 'Ubah Data Pencabutan SIP',
        breadcrumb: [
          {
            text: 'Ubah Data Pencabutan SIP',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pencabutan-sip/:idPendaftar/:idSIP/detail',
      name: 'pencabutan-sip-detail',
      component: () => import('@/views/appsalatiga/pencabutan-sip/detail.vue'),
      meta: {
        pageTitle: 'Detail Dokumen',
        breadcrumb: [
          {
            text: 'Detail Dokumen',
            active: true,
          },
        ],
      },
    },
  
  ]
  