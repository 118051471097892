export default [

    {
        path: '/perubahan-sip',
        name: 'perubahan-sip',
        component: () => import('@/views/appsalatiga/perubahan-sip/List.vue'),
        meta: {
            pageTitle: 'Data Perubahan SIP',
            breadcrumb: [
                {
                    text: 'Perubahan SIP',
                    active: true,
                },
            ],
        },
    },
]
