export default [

  {
    path: '/akun-sip',
    name: 'akun-sip',
    component: () => import('@/views/appsalatiga/akun-sip/List.vue'),
    meta: {
      pageTitle: 'Data Akun',
      breadcrumb: [
        {
          text: 'Akun',
          active: true,
        },
      ],
    },
  },
]
