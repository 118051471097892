export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/appsalatiga/dashboard/dashboard.vue'),
    meta: {
      pageTitle: 'Data Profil',
      breadcrumb: [
        {
          text: 'Profil',
          active: true,
        },
      ],
    },
  },
  
]
