export default [

    {
        path: '/duplikat-sip',
        name: 'duplikat-sip',
        component: () => import('@/views/appsalatiga/duplikat-sip/List.vue'),
        meta: {
            pageTitle: 'Data Duplikat SIP',
            breadcrumb: [
                {
                    text: 'Duplikat SIP',
                    active: true,
                },
            ],
        },
    },
]
